/* eslint-disable */
import abTestManager from '../shared/services/abTestManager';
import { getHostDomain, queryStringsExist, getQueryStrings, getArenaInfo } from '../shared/utils';

export class AdTagUrl  {
    static dfpVideoParams (config) {
        var url = (config.pageUrl) ? config.pageUrl : encodeURIComponent(document.location.href);
        return {
            iu: "/" + config.dfpNetworkCode + "/" + getHostDomain(config.adUnit),
            url: url,
            description_url: url,
            cust_params: AdTagUrl.getKeyValuesObject(config)
        }
    }
    static generate (config) {
        var url = config.pageUrl ? config.pageUrl : encodeURIComponent(document.location.href)
        var params = this.dfpVideoParams(config);
        var dfpWrapper = this.getDfpWrapper(config.firstLook);
        return dfpWrapper + "?sz=640x480" +
            "&iu=" + params.iu +
            "&cust_params=" + this.getKeyValuesString(config) +
            "&impl=s" +
            "&gdfp_req=1" +
            "&env=vp" +
            "&output=vast" +
            "&unviewed_position_start=1" +
            "&url=" + params.url +
            "&description_url=" + url +
            "&correlator=" + Math.random() * 10000000000000000;
    }

    static getDfpWrapper (firstLook) {
        var dfpWrapper = "//pubads.g.doubleclick.net/gampad/ads";
        if (firstLook) dfpWrapper = "//amsarkadium-a.akamaihd.net/assets/video/wrapper/first_look_dfpwrapper.aspx";
        return dfpWrapper;
    }

    static getKeyValues(config){
        var keyValues = [];
        if (typeof config.keyValues != "undefined" && config.keyValues.length > 0){
            if (typeof config.keyValues[0] != "object"){
                keyValues.push(config.keyValues);
            } else {
                keyValues = config.keyValues.slice();
            }
        }
        keyValues.push(["position", config.type]);
        keyValues.push(["product", config.product]);
        keyValues.push(["elementId", config.elementId]);

        if (abTestManager.adsABTestValue) keyValues.push(["adsABTest", abTestManager.adsABTestValue]);
        if (abTestManager.abTestValue) keyValues.push(["abtest", abTestManager.abTestValue]);

        try {
            const gameName = getGameName();
            if (gameName) {
                keyValues.push(["game_name", gameName]);
            }
        } catch (_) {}

        if (document.cookie.indexOf('ark_displayAd_split') !== -1) {
            keyValues.push(['abtest', document.cookie.replace(/(?:(?:^|.*;\s*)ark_displayAd_split\s*\=\s*([^;]*).*$)|^.*$/, '$1')]);  // eslint-disable-line
        }
        if (document.cookie.indexOf('arkabtests') !== -1) {
            keyValues.push(['abtest', document.cookie.replace(/(?:(?:^|.*;\s*)arkabtests\s*\=\s*([^;]*).*$)|^.*$/, '$1')]);  // eslint-disable-line
        }
        if (document.cookie.indexOf('ark_affiliate') !== -1) {
            keyValues.push(['ark_affiliate', document.cookie.replace(/(?:(?:^|.*;\s*)ark_affiliate\s*\=\s*([^;]*).*$)|^.*$/, '$1')]);  // eslint-disable-line
        }

        try {
            if (window.sessionStorage.arkDisplayAdSplit) {
                keyValues.push(['abtest', window.sessionStorage.arkDisplayAdSplit]);
            }
        } catch (e) {
            console.warn(e);
        }

        // UTM user tracking logic
        if (queryStringsExist()) {
            const allQueryStrings = getQueryStrings();
            Object.keys(allQueryStrings).forEach((key) => {
                keyValues.push([key, allQueryStrings[key]]);
            });
        } else {
            // delete this on Nov 1, 2020
            if (window.localStorage.getItem('utm_source')) {
                keyValues.push(['utm_source', window.localStorage.getItem('utm_source')]);
            }
            // delete this on Nov 1, 2020
            if (window.localStorage.getItem('utm_medium')) {
                keyValues.push(['utm_medium', window.localStorage.getItem('utm_medium')]);
            }
            // delete this on Nov 1, 2020
            if (window.localStorage.getItem('utm_campaign')) {
                keyValues.push(['utm_campaign', window.localStorage.getItem('utm_campaign')]);
            }
            // delete this on Nov 1, 2020
            if (window.localStorage.getItem('arkpromo')) {
                keyValues.push(['arkpromo', window.localStorage.getItem('arkpromo')]);
            }
            // END UTM user tracking logic
        }

        const info = getArenaInfo(window);
        Object.keys(info).forEach(key => keyValues.push([ key, info[key] ]));

        return keyValues;
    }

    static getKeyValuesObject(config){
        var customParams = {};
        try {
            var keyValues = this.getKeyValues(config);
            keyValues.forEach(function (keyValue) {
                if (typeof keyValue[0] != "undefined" && typeof keyValue[1] != "undefined") {
                    customParams[keyValue[0]] = keyValue[1];
                }
            });
        } catch(e){
            console.log("getKeyValuesObject failed with error " + e.message);
        }
        return customParams;
    }

    static getKeyValuesString(config){
        var customParams = "";
        try {
            var keyValues = this.getKeyValues(config);
            keyValues.forEach(function (keyValue) {
                if (typeof keyValue[0] != "undefined" && typeof keyValue[1] != "undefined") {
                    if (customParams != "") customParams += "%26";
                    customParams += [keyValue[0]] + "%3D" + keyValue[1];
                }
            });
        } catch(e){
            console.warn(e);
        }
        return customParams;
    }

    static addCustomParam (tagUrl, key, value) {
        if (typeof key == "undefined" || typeof value == "undefined" ) return tagUrl;
        var keyvalue = key + "%3D" + value;
        var ind = tagUrl.indexOf("cust_params=");
        if (ind >= 0) return tagUrl.slice(0, ind + 12) + keyvalue + "%26" + tagUrl.slice(ind + 12);
        return tagUrl + "&cust_params=" + keyvalue;
    }
    
    static appendAmazonUAMCustParam (tagUrl, custParamString) {
        // custParamString needs to be URL Encoded
        if (typeof custParamString == "undefined") return tagUrl;
        var ind = tagUrl.indexOf("cust_params=");
        if (ind >= 0) return tagUrl.slice(0, ind + 12) + custParamString + '%26' + tagUrl.slice(ind + 12);
        return tagUrl + "&cust_params=" + custParamString;
    }
}

function getGameName() {
    const xmlGameName = location.pathname.match(/\/arenaapi\/game\/(?<gameName>.+)\/html5/)?.groups?.gameName;

    if (xmlGameName) return xmlGameName;

    const { gameArena5Slug, game } = window.STORE?.getState() || {};

    if (gameArena5Slug) return gameArena5Slug;
    if (game?.slug) return game?.slug;

    return (this.href().match(/games?\/([0-9A-z-]+)/) || [])[1];
}
/* eslint-enable */
